import React from 'react'
import { Tooltip } from 'shared/components/tooltip'
import { ACTION_KEY } from '../constants'
import { BaseTableProps } from './base-table'

interface TableHeaderProps<T> extends Pick<BaseTableProps<T>, 'columns'> {
  totalWidth: number
}

export function TableHeader<T>({ columns, totalWidth }: TableHeaderProps<T>) {
  const headers = columns.map((column, index) => {
    const widthPercent = column.widthPercent || 25
    return (
      <div
        key={`headCell-${index}`}
        className={`text-start text-darkblue px-6 py-4 hidden lg:table-cell font-bold font-inter text-[17px] truncate ${
          column.className || ''
        }`}
        style={{
          width: `${column.key === ACTION_KEY && `90px`}`,
          flex: `${column.key !== ACTION_KEY && `${(widthPercent / totalWidth) * 100}`}`,
        }}
      >
        {typeof column.header === 'string' ? (
          <Tooltip mode="overflow" label={column.header}>
            <span className="truncate inline-block w-full">{column.header}</span>
          </Tooltip>
        ) : (
          column.header
        )}
      </div>
    )
  })

  return <div className="flex items-center bg-blue-100">{headers}</div>
}
