import React, { ReactNode, useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import { ActionMenuItem } from 'shared/components/table/components/action-menu-item'
import ActionMenu from 'shared/components/table/components/actions-render/action-menu'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { getDataTestAttributes } from 'shared/utils/get-data-test-attributes'
import { InstanceWithIdType, InstanceWithNestedIdType } from '../../types/instance-interface'
import { ActionMenuLinkItem } from '../action-menu-link-item'
import { ActionsInterface, ActionsRenderProps, CustomStateType } from './types'

export function ActionsRender<T, K>({
  actions,
  instance,
  caption,
  testAttributePostfix,
  nestedId,
  paginationIdField,
  actionsOrder,
}: ActionsRenderProps<T, K>) {
  const { t } = useLocoTranslation()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)

  const [isFetching, setIsFetching] = useState(false)

  const [isCustomOpen, setIsCustomOpen] = useState<CustomStateType<T>>(() => {
    const obj: CustomStateType<T> = {} as CustomStateType<T>
    actions.custom?.forEach(value => {
      obj[value.name] = false
    })
    return obj
  })

  const actionSideElements: JSX.Element[] = []

  const openLinkRender = () => {
    if (actions.openLink) {
      return actions.openLink.map(el => {
        const shouldRender = el.shouldRender ? el.shouldRender(instance) : true
        if (shouldRender) {
          return (
            <ActionMenuLinkItem
              isNext={el.isNext}
              openInNew={el.openInNew}
              dataTestAttributes={getDataTestAttributes(
                !!testAttributePostfix,
                `option-link-${
                  paginationIdField
                    ? instance[paginationIdField]
                    : nestedId
                    ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                    : (instance as InstanceWithIdType).id
                }`,
              )}
              key={`${el.name}-open-link`}
              href={el.setHref(instance)}
              label={el.caption ? el.caption : t('dashboard.actions.title.open')}
            />
          )
        }
      })
    }
  }

  const customRender = () => {
    if (actions.custom) {
      return actions.custom.map(el => {
        const shouldRender = el.shouldRender ? el.shouldRender(instance) : true
        const customActionRender = el.onActionRender
          ? el.onActionRender(
              isCustomOpen[el.name],
              () => setIsCustomOpen(prev => ({ ...prev, [el.name]: false })),
              instance,
              `${el.name}-modal`,
            )
          : undefined
        if (shouldRender) {
          customActionRender && actionSideElements.push(customActionRender)
          return (
            <ActionMenuItem
              key={`${el.name}-custom`}
              dataTestAttributes={getDataTestAttributes(
                !!testAttributePostfix,
                `option-${el.name}-${
                  paginationIdField
                    ? instance[paginationIdField]
                    : nestedId
                    ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                    : (instance as InstanceWithIdType).id
                }`,
              )}
              onClick={async () => {
                close()
                setIsCustomOpen(prev => ({ ...prev, [el.name]: true }))
                if (el.onActionClick) {
                  try {
                    setIsFetching(true)
                    await el.onActionClick(instance)
                    setIsFetching(false)
                  } catch (e) {
                    setIsFetching(false)
                  }
                }
              }}
              label={el.caption}
            />
          )
        }
      })
    }
  }

  const settingsRender = () => {
    if (actions.settings) {
      const settings = actions.settings
      const shouldRender = actions.settings.shouldRender
        ? actions.settings.shouldRender(instance)
        : true
      if (shouldRender) {
        actionSideElements.push(
          <EditModal
            key="settings-modal"
            opened={isSettingsOpen}
            onOpen={settings.onModalOpen}
            isPreFetching={settings.isFetching}
            onClose={() => {
              setIsSettingsOpen(false)
            }}
            fullCaption={settings.fullCaption}
            afterLeave={settings.onModalClose}
            caption={caption}
            editable={settings.editable}
            instance={instance}
            additionalData={settings.additionalData}
            onEdit={data => settings.onEdit(instance, data)}
            toastCaption={
              settings.toastCaption
                ? settings.toastCaption
                : t('dashboard.actions.updated', { module: caption })
            }
          />,
        )
        return (
          <ActionMenuItem
            key={'action-settings'}
            onClick={() => {
              setIsSettingsOpen(true)
              close()
            }}
            label={t('dashboard.actions.title.settings')}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-settings-${
                paginationIdField
                  ? instance[paginationIdField]
                  : nestedId
                  ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                  : (instance as InstanceWithIdType).id
              }`,
            )}
          />
        )
      }
    }
  }

  const editRender = () => {
    if (actions.edit) {
      const shouldRender = actions.edit.shouldRender ? actions.edit.shouldRender(instance) : true
      const edit = actions.edit
      if (shouldRender) {
        return (
          <ActionMenuLinkItem
            key={'action-edit'}
            label={t('dashboard.actions.title.edit')}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-link-${
                paginationIdField
                  ? instance[paginationIdField]
                  : nestedId
                  ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                  : (instance as InstanceWithIdType).id
              }`,
            )}
            href={edit.setHref(instance)}
            openInNew={edit.openInNew}
            isNext={edit.isNext}
          />
        )
      }
    }
  }

  const activateRender = () => {
    if (actions.activate) {
      const isActive = instance[(actions.activate.customStatusField || 'active') as keyof T]
      const activate = actions.activate
      const shouldRender = actions.activate.shouldRender
        ? actions.activate.shouldRender(instance)
        : true
      const confirmationText = actions.activate.confirmationText
        ? actions.activate.confirmationText
        : 'dashboard.actions.activate_confirmation'

      if (shouldRender) {
        actionSideElements.push(
          <Confirmation
            key="activate-modal"
            opened={isActivateOpen}
            onClose={() => setIsActivateOpen(false)}
            onConfirm={() => activate.onChange(instance)}
            confirmationText={t(confirmationText, {
              activate: isActive
                ? t('global.deactivate').toLowerCase()
                : t('global.activate').toLowerCase(),
              module: caption?.toLowerCase(),
            })}
            toastCaption={t('dashboard.actions.activated', {
              module: caption,
              activated: isActive
                ? t('dashboard.actions.deactivated_caption').toLowerCase()
                : t('dashboard.actions.activated_caption').toLowerCase(),
            })}
          />,
        )

        const dataTestPostfix = isActive
          ? `option-deactivate-${
              paginationIdField
                ? instance[paginationIdField]
                : nestedId
                ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                : (instance as InstanceWithIdType).id
            }`
          : `option-activate-${
              paginationIdField
                ? instance[paginationIdField]
                : nestedId
                ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                : (instance as InstanceWithIdType).id
            }`

        return (
          <ActionMenuItem
            key={'action-activate'}
            dataTestAttributes={getDataTestAttributes(!!testAttributePostfix, dataTestPostfix)}
            onClick={() => {
              setIsActivateOpen(true)
              close()
            }}
            label={
              isActive
                ? t('dashboard.actions.title.deactivate')
                : t('dashboard.actions.title.activate')
            }
          />
        )
      }
    }
  }

  const removeRender = () => {
    if (actions.remove) {
      const remove = actions.remove
      const shouldRender = actions.remove.shouldRender
        ? actions.remove.shouldRender(instance)
        : true
      if (shouldRender) {
        actionSideElements.push(
          <Confirmation
            key="remove-modal"
            opened={isRemoveOpen}
            onClose={() => setIsRemoveOpen(false)}
            onConfirm={() => remove.onChange(instance)}
            confirmationText={t('dashboard.actions.remove_confirmation', {
              module: caption?.toLowerCase(),
            })}
            toastCaption={
              actions.remove.showSuccessToast !== false &&
              t('dashboard.actions.removed', { module: caption })
            }
          />,
        )

        return (
          <ActionMenuItem
            key={'action-remove'}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-remove-${
                paginationIdField
                  ? instance[paginationIdField]
                  : nestedId
                  ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
                  : (instance as InstanceWithIdType).id
              }`,
            )}
            onClick={() => {
              setIsRemoveOpen(true)
              close()
            }}
            label={t('dashboard.actions.title.remove')}
          />
        )
      }
    }
  }

  const getActionsToRender = () => {
    const actionsToRender: Record<keyof ActionsInterface<T, K>, () => ReactNode> = {
      openLink: openLinkRender,
      custom: customRender,
      settings: settingsRender,
      edit: editRender,
      activate: activateRender,
      remove: removeRender,
    }
    if (actionsOrder) {
      const sortedActionsKeys = (
        Object.keys(actionsToRender) as Array<keyof ActionsInterface<T, K>>
      ).sort((a, b) => {
        const indexA = actionsOrder.indexOf(a)
        const indexB = actionsOrder.indexOf(b)
        return indexA - indexB
      })

      return sortedActionsKeys.reduce((acc, key) => {
        acc[key] = actionsToRender[key]
        return acc
      }, {} as Record<keyof ActionsInterface<T, K>, () => ReactNode>)
    }
    return actionsToRender
  }

  const renderActions = Object.values(getActionsToRender()).map(renderFunction => renderFunction())

  const { show, delayClose, delayOpen, close } = useFloatHover()

  return (
    <div className={`flex items-center`}>
      <ActionMenu
        delayClose={delayClose}
        delayOpen={delayOpen}
        show={show}
        isFetching={isFetching}
        dataTestAttributes={getDataTestAttributes(
          !!testAttributePostfix,
          `options-${
            paginationIdField
              ? instance[paginationIdField]
              : nestedId
              ? (instance as InstanceWithNestedIdType<T>)[nestedId].id
              : (instance as InstanceWithIdType).id
          }`,
        )}
        menuItems={renderActions}
      />
      {actionSideElements.map(el => (
        <div key={el.key}>{el}</div>
      ))}
    </div>
  )
}
